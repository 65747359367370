import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query Categories($ids: [String]) {
    categoryList(filters: { ids: { in: $ids } }) {
      id
      name
      url_key
      url_path
      description
      meta_title
      meta_description
      meta_keywords
      image
      product_count
      products {
        total_count
      }
      ... on CategoryTree {
        children {
          id
          name
          url_key
          url_path
          meta_description
          description
          meta_keywords
          image
          product_count
          products {
            total_count
          }
          ... on CategoryTree {
            children {
              id
              name
              url_key
              url_path
              meta_description
              description
              meta_keywords
              image
              product_count
              products {
                total_count
              }
              ... on CategoryTree {
                children {
                  id
                  name
                  url_key
                  url_path
                  meta_description
                  description
                  meta_keywords
                  image
                  product_count
                }
              }
            }
          }
        }
      }
    }
  }
`;
