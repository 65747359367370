import { gql } from '@apollo/client';
import { CART_FIELDS } from 'graphql/fragments/cart';

export const UPDATE_CART_ITEM = gql`
  ${CART_FIELDS}

  mutation UpdateCartItem($cartId: String!, $uid: ID!, $quantity: Float) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: [{ cart_item_uid: $uid, quantity: $quantity }]
      }
    ) {
      cart {
        ...CartFields
      }
    }
  }
`;
