// Baltbaby logo

export const Logo = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="191.271"
      height="35.876"
      viewBox="0 0 191.271 35.876"
    >
      <path
        d="M241.063,163.942c0-10.5-15.269-9.821-15.269-.373,0,10.438,15.269,10.662,15.269.373m22.1-7.7c-10.263,0-10.284,15.315-.093,15.315C273.055,171.553,273.134,156.238,263.164,156.238Zm-1.214,21.385c-7.75,0-12.747-6.158-12.747-13.729,0-7.468,5.043-13.775,12.935-13.775a10.08,10.08,0,0,1,8.684,4.484v-3.689h5.744v20.318C270.654,170.085,270.146,177.623,261.949,177.623Zm22.767-.794H278.5V145.1h6.211Zm14.921-20.359H295.9V176.83h-6.21V156.471h-3.036v-5.556h3.036V145.1h6.21v5.817h3.736Zm111.59-5.556-12.976,30.058H391.57l2.847-6.6-9.48-23.456h7.052l5.93,16.622,6.209-16.622Zm-88.545,13.027c0-10.5-15.269-9.821-15.269-.373C307.413,174.007,322.682,174.23,322.682,163.942Zm6.21-.047c0,7.313-5.2,13.729-12.841,13.729-8.474,0-8.341-7.617-14.475-6.382V145.1h6.21v8.571c1.833-2.52,5.065-3.549,8.077-3.549C323.517,150.12,328.893,156.467,328.893,163.895Zm44.876-7.657c-10.551,0-10.158,15.315,0,15.315C383.754,171.553,383.631,156.238,373.769,156.238Zm13.682,7.657c0,7.313-5.2,13.729-12.841,13.729-8.474,0-8.341-7.617-14.475-6.382V145.1h6.21v8.571c1.833-2.52,5.065-3.549,8.078-3.549C382.075,150.12,387.451,156.467,387.451,163.895Zm-42.657-7.657c-10.264,0-10.285,15.315-.094,15.315C354.684,171.553,354.763,156.238,344.794,156.238Zm-1.214,21.385c-7.75,0-12.747-6.158-12.747-13.729,0-7.468,5.043-13.775,12.934-13.775a10.082,10.082,0,0,1,8.685,4.484v-3.689H358.2v20.318C352.283,170.085,351.775,177.623,343.579,177.623Zm-96.307-13.729c0,7.313-5.195,13.729-12.841,13.729-8.475,0-8.342-7.617-14.475-6.382V145.1h6.21v8.571c1.833-2.52,5.064-3.549,8.077-3.549C241.9,150.12,247.272,156.467,247.272,163.895Z"
        transform="translate(-219.957 -145.098)"
        fill={fill || '#161615'}
      />
    </svg>
  );
};

/**
 * Chevron
 *
 * Thick vertical chevron
 *
 * @prop {string} fill - prop to override icon color
 */

export const Chevron = ({ fill, transform }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.93"
      height="8.118"
      viewBox="0 0 6.93 8.118"
    >
      <path
        id="Path_395"
        data-name="Path 395"
        d="M-3.429-2.394.6-6.462l-4.032-4.05h2.9L3.5-6.462-.531-2.394Z"
        transform={transform || 'translate(3.429 10.512)'}
        fill={fill}
      />
    </svg>
  );
};

/**
 * SliderArrowRight
 *
 * Arrow (outline) for carousel/slider (right side)
 *
 * @prop {number} strokeWidth - prop to override stroke width
 */

export const SliderArrowRight = ({ strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.199"
      height="46.399"
      viewBox="0 0 26.199 46.399"
    >
      <path
        id="sliderArrowRight"
        data-name="sliderArrowRight"
        d="M6243.552,1243l18.957,18.957-18.957,18.957"
        transform="translate(-6239.31 -1238.757)"
        fill="none"
        stroke="#96e7e7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

/**
 * SliderArrowLeft
 *
 * Arrow (outline) for carousel/slider (left side)
 *
 * @prop {number} strokeWidth - prop to override stroke width
 */

export const SliderArrowLeft = ({ strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.199"
      height="46.399"
      viewBox="0 0 26.199 46.399"
    >
      <path
        id="sliderArrowLeft"
        data-name="sliderArrowLeft"
        d="M6262.509,1243l-18.957,18.957,18.957,18.957"
        transform="translate(-6240.552 -1238.757)"
        fill="none"
        stroke="#96e7e7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

/**
 * HorizontalChevron
 *
 * Horizontal arrow with rounded corners
 *
 */

export const HorizontalChevron = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.621"
    height="20.243"
    viewBox="0 0 11.621 20.243"
  >
    <path
      id="Path_95"
      data-name="Path 95"
      d="M8966,40l-8,8,8,8"
      transform="translate(-8956.5 -37.879)"
      fill="none"
      stroke="#222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

/**
 * VerticalChevron
 *
 * Vertical arrow with rounded corners
 *
 * @prop {string} stroke - to override stroke color
 */

export const VerticalChevron = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.405"
      height="7.617"
      viewBox="0 0 12.405 7.617"
    >
      <path
        id="Path_9"
        data-name="Path 9"
        d="M1537.238,260l4.788,4.788,4.788-4.788"
        transform="translate(-1535.824 -258.586)"
        fill="none"
        stroke={stroke || '#393939'}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

/**
 * Rounded x icon with background for dismissal
 *
 * @prop {string} bg - to override background
 * @prop {string} stroke - to override x-stroke
 */

export const Close = ({ bg, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="close-button"
        data-name="close-button"
        transform="translate(-1190 -222)"
      >
        <circle
          id="close-circle"
          data-name="close-circle"
          cx="14"
          cy="14"
          r="14"
          transform="translate(1190 222)"
          fill={bg || '#efefef'}
        />
        <g
          id="close-cross"
          data-name="close-cross"
          fill="none"
          stroke={stroke || '#707070'}
          strokeLinecap="round"
          strokeWidth="2"
        >
          <path
            id="close-cross-1"
            data-name="close-cross-1"
            d="M0,0V13.362"
            transform="translate(1208.725 231.276) rotate(45)"
          />
          <path
            id="close-cross-2"
            data-name="close-cross-2"
            d="M0,0V13.362"
            transform="translate(1199.275 231.276) rotate(-45)"
          />
        </g>
      </g>
    </svg>
  );
};

export const Heart = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.561"
      height="16"
      viewBox="0 0 17.561 16"
    >
      <g data-name="heartIcon" fill={fill || 'none'}>
        <path
          d="M8.78,16C4.059,12.805,1.583,9.9.568,7.467-2.153.948,5.618-2.169,8.78,1.679c3.163-3.848,10.933-.731,8.213,5.788C15.978,9.9,13.5,12.805,8.78,16Z"
          stroke="none"
        />
        <path
          d="M 8.780490875244141 14.78642559051514 C 13.51610946655273 11.47636032104492 15.36034679412842 8.782322883605957 16.07041168212891 7.081501483917236 C 16.76262092590332 5.422871589660645 16.72318077087402 3.937801361083984 15.95636081695557 2.786851406097412 C 15.22203063964844 1.684681415557861 13.87898063659668 1.000001430511475 12.45132064819336 1.000001430511475 C 11.27869129180908 1.000001430511475 10.24939060211182 1.466581463813782 9.553020477294922 2.313791513442993 L 8.780490875244141 3.253661394119263 L 8.007961273193359 2.313791513442993 C 7.311600685119629 1.466581463813782 6.28230094909668 1.000001430511475 5.109661102294922 1.000001430511475 C 3.6821608543396 1.000001430511475 2.339180946350098 1.684731483459473 1.604800820350647 2.786991357803345 C 0.8379308581352234 3.938011407852173 0.7984208464622498 5.423031330108643 1.490520834922791 7.081381320953369 C 2.200633525848389 8.782318115234375 4.044871807098389 11.47636032104492 8.780490875244141 14.78642559051514 M 8.780490875244141 16.00000190734863 C 4.059280872344971 12.80533123016357 1.58319091796875 9.899031639099121 0.5677108764648438 7.466641426086426 C -1.338940858840942 2.898041963577271 1.907844185829163 1.342773359169769e-08 5.109655857086182 1.342773359169769e-08 C 6.476204872131348 1.342773359169769e-08 7.834371089935303 0.5277435183525085 8.780490875244141 1.678811430931091 C 9.726417541503906 0.5279783606529236 11.08505916595459 1.342773359169769e-08 12.45132541656494 1.342773359169769e-08 C 15.65340900421143 1.342773359169769e-08 18.90008926391602 2.89764404296875 16.99327087402344 7.466641426086426 C 15.97779083251953 9.899031639099121 13.50170040130615 12.80533123016357 8.780490875244141 16.00000190734863 Z"
          stroke="none"
          fill="#fe93bf"
        />
      </g>
    </svg>
  );
};

// Instagram dark icon

export const Instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.518"
    height="31.51"
    viewBox="0 0 31.518 31.51"
  >
    <path
      id="Icon_awesome-instagram"
      data-name="Icon awesome-instagram"
      d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
      transform="translate(0.005 -2.238)"
    />
  </svg>
);

// Facebook dark icon

export const Facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.163"
    height="30.163"
    viewBox="0 0 30.163 30.163"
  >
    <path
      id="Icon_awesome-facebook-square"
      data-name="Icon awesome-facebook-square"
      d="M26.931,2.25H3.232A3.232,3.232,0,0,0,0,5.482v23.7a3.232,3.232,0,0,0,3.232,3.232h9.241V22.158H8.231V17.332h4.242V13.653c0-4.184,2.491-6.5,6.307-6.5a25.7,25.7,0,0,1,3.738.326V11.59H20.412A2.414,2.414,0,0,0,17.691,14.2v3.134h4.631l-.741,4.827h-3.89V32.413h9.241a3.232,3.232,0,0,0,3.232-3.232V5.482A3.232,3.232,0,0,0,26.931,2.25Z"
      transform="translate(0 -2.25)"
    />
  </svg>
);

export const FacebookLight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.569"
    height="16"
    viewBox="0 0 8.569 16"
  >
    <path
      id="Icon_awesome-facebook-f"
      data-name="Icon awesome-facebook-f"
      d="M9.617,9l.444-2.9H7.283V4.225A1.448,1.448,0,0,1,8.916,2.661h1.263V.2A15.4,15.4,0,0,0,7.937,0,3.535,3.535,0,0,0,4.153,3.9V6.1H1.609V9H4.153v7h3.13V9Z"
      transform="translate(-1.609)"
      fill="#fff"
    />
  </svg>
);

export const Google = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.696"
    height="16"
    viewBox="0 0 15.696 16"
  >
    <path
      id="Icon_ionic-logo-google"
      data-name="Icon ionic-logo-google"
      d="M19.26,10.149l-.081-.342H11.735v3.151h4.447A4.452,4.452,0,0,1,11.827,16.3a5.208,5.208,0,0,1-3.5-1.4A5,5,0,0,1,6.83,11.376,5.161,5.161,0,0,1,8.3,7.848a4.986,4.986,0,0,1,3.482-1.362A4.541,4.541,0,0,1,14.74,7.641l2.239-2.227A7.922,7.922,0,0,0,11.7,3.382h0A8.154,8.154,0,0,0,5.934,5.729,8.121,8.121,0,0,0,3.656,11.38a8.039,8.039,0,0,0,2.189,5.559,8.351,8.351,0,0,0,6.021,2.443,7.427,7.427,0,0,0,5.409-2.274,7.986,7.986,0,0,0,2.077-5.532A9.211,9.211,0,0,0,19.26,10.149Z"
      transform="translate(-3.656 -3.382)"
      fill="#fff"
    />
  </svg>
);

/**
 * Checkmark
 *
 * Checkmark icon
 *
 * @prop {string} stroke - to override stroke color
 * @prop {string} width - to override stroke width
 */

export const Checkmark = ({ stroke, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.988"
      height="12.089"
      viewBox="0 0 16.988 12.089"
    >
      <path
        id="checkmark"
        data-name="checkmark"
        d="M13776.561,8187.6l4.07,4.069.18-.18,7.082-7.082"
        transform="translate(-13773.732 -8181.58)"
        fill="none"
        stroke={stroke || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={width || '4'}
      />
    </svg>
  );
};

export const Sad404Teddy = () => {
  return (
    <svg
      id="Group_1383"
      data-name="Group 1383"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="126.852"
      height="178.392"
      viewBox="0 0 126.852 178.392"
    >
      <g id="Group_1382" data-name="Group 1382">
        <path
          id="Path_975"
          data-name="Path 975"
          d="M139.078,137.212c2.042,1.181,4.822.187,6.209-2.213s.856-5.307-1.187-6.484-4.823-.187-6.21,2.214-.854,5.3,1.189,6.484"
          transform="translate(-90.716 -84.696)"
          fill="#1a1718"
        />
        <path
          id="Path_976"
          data-name="Path 976"
          d="M218.289,137.212c2.041-1.179,2.573-4.081,1.187-6.484s-4.167-3.392-6.211-2.214-2.574,4.081-1.186,6.484,4.166,3.394,6.21,2.213"
          transform="translate(-139.799 -84.696)"
          fill="#1a1718"
        />
        <path
          id="Path_977"
          data-name="Path 977"
          d="M250.184,289.127c5.328-22.353-1.779-37.8-8.679-46.83a56.066,56.066,0,0,0-11.273-11.05,43.8,43.8,0,0,1-2.659,4.113,51.237,51.237,0,0,1,10.05,9.9,45.78,45.78,0,0,1,8.244,17.237,51.116,51.116,0,0,1,.917,17.863,32.994,32.994,0,0,0-12.257-13.625,54.6,54.6,0,0,0-9.793-27.871,44.08,44.08,0,0,1-3.514,3.5,49.519,49.519,0,0,1,8.16,21.9,27.56,27.56,0,0,0-9.459-1.674c-16.924,0-30.692,15.43-30.692,34.4s13.768,34.4,30.692,34.4,30.692-15.43,30.692-34.4a38.508,38.508,0,0,0-.682-7.224,2.523,2.523,0,0,0,.25-.637M219.923,326.5c-10.068,0-18.261-9.409-18.261-20.976s8.193-20.978,18.261-20.978,18.261,9.41,18.261,20.978S229.99,326.5,219.923,326.5m22.551-15.175a28.722,28.722,0,0,0,.6-5.8c0-14.261-10.383-25.861-23.146-25.861s-23.145,11.6-23.145,25.861a28.722,28.722,0,0,0,.594,5.8,32.7,32.7,0,0,1-3.256-14.338c0-16.275,11.577-29.514,25.807-29.514s25.807,13.239,25.807,29.514a32.676,32.676,0,0,1-3.256,14.338"
          transform="translate(-125.194 -152.992)"
          fill="#1a1718"
        />
        <path
          id="Path_978"
          data-name="Path 978"
          d="M56.607,163.744A37.1,37.1,0,0,0,62.184,144c0-18.969-13.767-34.4-30.691-34.4-11.183,0-20.987,6.739-26.352,16.782-1.29-12.813,1.773-24.2,9.157-33.945A50.937,50.937,0,0,1,27.621,80.225q.812,1.154,1.7,2.249a52,52,0,0,0-8.6,14.375,2.442,2.442,0,1,0,4.536,1.813,47.247,47.247,0,0,1,7.369-12.525,43.784,43.784,0,0,0,73.363-41.4A22.9,22.9,0,0,0,99.1,0,22.7,22.7,0,0,0,85.331,4.6a23.143,23.143,0,0,0-7.241,9.186,44,44,0,0,0-29.329,0A23.127,23.127,0,0,0,41.521,4.6,22.9,22.9,0,0,0,4.854,22.9,22.927,22.927,0,0,0,20.866,44.741a43.834,43.834,0,0,0,4.162,31.336A55.383,55.383,0,0,0,10.541,89.3c-6.843,8.95-13.886,24.22-8.809,46.27A38.25,38.25,0,0,0,.8,144c0,18.467,13.057,33.58,29.366,34.363h.02c.345.014.693.026,1.04.028.045,0,.088,0,.134,0h.132a29.084,29.084,0,0,0,21.9-10.32,41.983,41.983,0,0,0,14.853.818,34.884,34.884,0,0,1-2.924-4.64,37.75,37.75,0,0,1-8.711-.506M25.967,41.724a2.445,2.445,0,0,0-1.631-1.138A18.015,18.015,0,1,1,44.995,17.668a2.443,2.443,0,0,0,3.29,1.541,39.041,39.041,0,0,1,30.283,0,2.441,2.441,0,0,0,3.288-1.541,18.016,18.016,0,1,1,20.659,22.917,2.443,2.443,0,0,0-1.875,3.111,38.9,38.9,0,1,1-74.428,0,2.441,2.441,0,0,0-.244-1.973M31.229,173.5c-10.008-.081-18.13-9.457-18.13-20.972,0-11.568,8.191-20.978,18.261-20.978s18.26,9.41,18.26,20.978c0,11.549-8.168,20.947-18.216,20.974l-.175,0m.263-59.023C45.724,114.481,57.3,127.72,57.3,144a32.609,32.609,0,0,1-3.484,14.793,28.685,28.685,0,0,0,.687-6.256c0-14.261-10.382-25.862-23.143-25.862s-23.145,11.6-23.145,25.862a28.815,28.815,0,0,0,.493,5.314A32.747,32.747,0,0,1,5.684,144c0-16.275,11.577-29.514,25.808-29.514"
          transform="translate(0 0)"
          fill="#1a1718"
        />
        <path
          id="Path_979"
          data-name="Path 979"
          d="M181.1,166.566c0,2.915-3.542,6.267-6.937,6.267s-6.669-3.232-6.669-6.147,3.273-4.2,6.669-4.2,6.937,1.16,6.937,4.075"
          transform="translate(-110.812 -107.503)"
          fill="#1a1718"
        />
        <path
          id="Path_980"
          data-name="Path 980"
          d="M175.421,211.959a1.683,1.683,0,0,1-.853-.232c-.018-.01-3.118-1.71-9.764-1.71a29.724,29.724,0,0,0-10.7,1.8,1.692,1.692,0,0,1-1.349-3.1,32.462,32.462,0,0,1,12.052-2.083c7.673,0,11.322,2.085,11.474,2.174a1.692,1.692,0,0,1-.857,3.152"
          transform="translate(-100.388 -136.707)"
          fill="#1a1818"
        />
      </g>
    </svg>
  );
};

export const Little500Dino = () => {
  return (
    <svg
      id="Group_1386"
      data-name="Group 1386"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="235.794"
      height="172.37"
      viewBox="0 0 235.794 172.37"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_385"
            data-name="Rectangle 385"
            width="235.794"
            height="172.37"
            fill="none"
            stroke="#707070"
            strokeWidth="4"
          />
        </clipPath>
      </defs>
      <g id="Group_1385" data-name="Group 1385" clipPath="url(#clip-path)">
        <path
          id="Path_982"
          data-name="Path 982"
          d="M103.272,104.948c-9.408-18.5,18.151-39.45,7.61-45.977a19.266,19.266,0,0,1-14.206,6.106c-10.4,0-18.825-7.945-18.825-17.745s8.478-16.754,18.825-17.746c65.556-6.287,31.371,97.375,85.529,63.769,5.23,24.807-24.347,29.266-31.212,30.21"
          transform="translate(-50.775 -19.116)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_983"
          data-name="Path 983"
          d="M213.568,292.766q-3.489-1.179-6.458-2.426"
          transform="translate(-135.079 -189.362)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_984"
          data-name="Path 984"
          d="M235.2,255.511c-.526-3.641-4.225-6.187-8.208-6.187h-.025c-5.16,0-9.222,4.44-7.915,9.043,1.544,5.437,2.207,12.426-2.57,17.076a2.3,2.3,0,0,0,1.627,3.927H235.5C249.913,275.542,236.5,264.479,235.2,255.511Z"
          transform="translate(-140.745 -162.611)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_985"
          data-name="Path 985"
          d="M159.629,243.122c-.431-2.979-3.457-5.062-6.717-5.062h-.02c-4.222,0-7.546,3.633-6.476,7.4,1.2,4.222,1.341,9.588-2.195,13.376a2.3,2.3,0,0,0,1.754,3.809h13.9C171.667,259.512,160.688,250.461,159.629,243.122Z"
          transform="translate(-93.681 -155.265)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_986"
          data-name="Path 986"
          d="M159.2,14.737c3.879-3.563,10.032-8.544,13.068-7.438,2.807,1.023,4.329,7.757,5.091,12.9"
          transform="translate(-103.833 -4.658)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_987"
          data-name="Path 987"
          d="M221.1,54.062c4.908-.528,14.969-1.192,16.945,1.941,1.956,3.1-2.84,11.8-5.431,16.058"
          transform="translate(-144.206 -34.968)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_988"
          data-name="Path 988"
          d="M258.641,122.178c4.635.836,11.737,2.56,12.608,5.383.837,2.713-3.654,7.855-6.978,11.191"
          transform="translate(-168.688 -79.685)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_989"
          data-name="Path 989"
          d="M287.083,199.515c2.988-.748,8.669-1.9,10.083-.236,1.3,1.523-.3,6.235-1.5,9.2"
          transform="translate(-187.238 -129.415)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_990"
          data-name="Path 990"
          d="M337.089,213.344s.514-6.488,2.269-7.1,6.167,4.145,6.167,4.145"
          transform="translate(-219.852 -134.478)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_991"
          data-name="Path 991"
          d="M141.916,58.239a4.609,4.609,0,1,1-4.609-4.624A4.616,4.616,0,0,1,141.916,58.239Z"
          transform="translate(-86.547 -34.968)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_992"
          data-name="Path 992"
          d="M132.547,106.654c0,6.2-7.147,8.731-7.147,8.731"
          transform="translate(-81.787 -69.561)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_993"
          data-name="Path 993"
          d="M218.666,149.234a9.384,9.384,0,0,1,4.563,7.976c0,4.481-2.282,10.016-2.282,10.016"
          transform="translate(-142.616 -97.332)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_994"
          data-name="Path 994"
          d="M226.024,206.361c-1.845-1.68-1.087-5.394-1.087-5.394"
          transform="translate(-146.605 -131.072)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_995"
          data-name="Path 995"
          d="M210.359,202.789c1.4-2.069,5.17-1.837,5.17-1.837"
          transform="translate(-137.198 -131.057)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_996"
          data-name="Path 996"
          d="M230.711,200.966c-2.007,1.482-5.485,0-5.485,0"
          transform="translate(-146.894 -131.072)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_997"
          data-name="Path 997"
          d="M131.429,170.837a8.942,8.942,0,0,1-3.823.832c-4.466,0-9.983-2.289-9.983-2.289"
          transform="translate(-76.715 -110.47)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_998"
          data-name="Path 998"
          d="M102.164,170.18c1.675-1.852,5.377-1.091,5.377-1.091"
          transform="translate(-66.632 -110.18)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_999"
          data-name="Path 999"
          d="M112.357,154.463c2.062,1.4,1.832,5.187,1.832,5.187"
          transform="translate(-73.28 -100.742)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_1000"
          data-name="Path 1000"
          d="M116.393,174.881c-1.477-2.014,0-5.5,0-5.5"
          transform="translate(-75.484 -110.47)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_1001"
          data-name="Path 1001"
          d="M46.185,139.353h0A7.994,7.994,0,0,1,41.5,149.641l-7.487,2.8-5.6-14.975,7.487-2.8A7.993,7.993,0,0,1,46.185,139.353Z"
          transform="translate(-18.529 -87.498)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Line_155"
          data-name="Line 155"
          x1="8.755"
          y2="3.274"
          transform="translate(5.538 61.764)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Line_156"
          data-name="Line 156"
          x1="8.755"
          y2="3.274"
          transform="translate(2.484 53.599)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_1002"
          data-name="Path 1002"
          d="M82.315,146.68c6.109,2.6,10.453,8.24,13.263,14.255s-1.012,20.232-3.672,26.315c-25.933,59.321,49.142,45.9,80.8,50.763a23.616,23.616,0,0,0,9.776-.054c3.168-.865,6.131-3.126,7-6.292,1.137-4.13-1.637-8.53-5.345-10.674s-8.132-2.559-12.4-2.927c-6.953-.6-14.411-1.092-20.448,2.408s-9.192,12.683-4.241,17.6c1.893,1.881,4.529,2.766,7.066,3.592l52.262,17.011c11.459,3.73,23.252,7.505,35.281,6.784,16.9-1.013,31.946-10.72,45.333-21.077"
          transform="translate(-53.686 -95.666)"
          fill="none"
          stroke="#1a1718"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};
