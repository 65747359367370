import { gql } from '@apollo/client';

export const CUSTOMER = gql`
  query GetCustomer {
    customer {
      firstname
      lastname
      email
      addresses {
        id
        street
        city
        region {
          region
          region_code
          region_id
        }
        country_code
        postcode
        telephone
      }
      orders {
        items {
          id
          number
          order_date
          status
          total {
            grand_total {
              value
            }
          }
          carrier
          items {
            product_name
            product_sku
            product_url_key
          }
          invoices {
            id
            number
          }
        }
        total_count
      }
      wishlists {
        id
        items_count
        items_v2 {
          items {
            id
            product {
              __typename
              sku
              name
              url_key
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              price {
                regularPrice {
                  amount {
                    value
                  }
                }
              }
              image {
                url
                label
              }
              ... on ConfigurableProduct {
                variants {
                  attributes {
                    uid
                    code
                    label
                  }
                  product {
                    sku
                    image {
                      url
                      label
                    }
                    media_gallery {
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
