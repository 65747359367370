import { gql } from '@apollo/client';

export const BLOG_POST = gql`
  query BlogPost($id: String!) {
    blogPost(id: $id) {
      title
      identifier
      content
      featured_image
      media_gallery
    }
  }
`;
