import { gql } from '@apollo/client';

export const CATEGORIES_BASE_DATA = gql`
  query Categories($ids: [String]) {
    categoryList(filters: { ids: { in: $ids } }) {
      id
      name
      url_key
      ... on CategoryTree {
        children {
          id
          name
          url_key
          ... on CategoryTree {
            children {
              id
              name
              url_key
              ... on CategoryTree {
                children {
                  id
                  name
                  url_key
                  ... on CategoryTree {
                    children {
                      id
                      name
                      url_key
                      ... on CategoryTree {
                        children {
                          id
                          name
                          url_key
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;