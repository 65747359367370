import { gql } from '@apollo/client';

export const SET_SHIPPING_METHOD = gql`
  mutation SetShippingMethod(
    $cartId: String!
    $carrierCode: String!
    $methodCode: String!
  ) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cartId
        shipping_methods: [
          { carrier_code: $carrierCode, method_code: $methodCode }
        ]
      }
    ) {
      cart {
        id
      }
    }
  }
`;
