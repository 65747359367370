import { atom } from 'jotai';
import { atomWithStorage, atomWithReset } from 'jotai/utils';
import { USER_TOKEN } from './constants';
import { sortOptions } from './data';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Authentication state (if session token exists in cookies)
export const authAtom = atom({ token: cookies.get(USER_TOKEN) || null });

// Logged-in customer data
export const customerAtom = atom(null);

// State of account view (in form-mode or not)
export const accountFormAtom = atom({ credentials: false, address: false });

// Categories (navigation menu)
export const categoriesAtom = atom(null);

// Product filters (besides category) in catalogue
export const filtersInitialState = {
  price: {},
  brand: [],
  isofix: [],
  kaal: [],
  karu_kaal: [],
  kandevoime: [],
  kasutusaeg: [],
  laius: [],
  laste_jalgrattatoolid: [],
  madrats_komplektis: [],
  madratsi_moodud: [],
  mudel: [],
  otstarve: [],
  rataste_arv: [],
  v__rv: []
};
export const filtersAtom = atomWithReset(filtersInitialState);
export const filtersResetAtom = atom(false);

// Product sorting option in catalogue
export const sortAtom = atom(sortOptions.et.content[0]);

// Current page number in catalogue
export const currentPageAtom = atom(1);
export const pageSizeAtom = atom(24);

// Currently selected product ID
export const productIdAtom = atom(null);

// Currently selected product variants
export const productVariantsAtom = atom([]);

// Product price (depending on product range, differing prices, discount)
export const productPriceAtom = atom({ value: null, discount: null });

// Product image gallery
export const productImagesAtom = atom(null);

// Current cart data
export const cartAtom = atom(null);

// Cart open?
export const cartSidebarAtom = atom(false);

// Menu in mobile open?
export const accountFlyoutAtom = atom(false);

// Newsletter modal toggled?
export const newsletterModalAtom = atom(false);

// Auth modal toggled?
export const authModalAtom = atom(false);

// Reset password modal toggled?
export const resetPasswordModalAtom = atom(false);

// Catalogue filter in mobile open?
export const openFilterAtom = atom(false);

// Product image gallery (lightbox) open?
export const lightboxAtom = atom(false);

// Current index of an image in a product gallery
export const currentGalleryIndexAtom = atom(0);

// Error message in case variants not selected (of conf. product)
export const addToCartErrorAtom = atom(false);

// Selected parcel locker location in localStorage (temp for saving to Magento)
export const parcelLockerLocationAtom = atomWithStorage(
  'baltbaby_locker_location',
  null
);

// Current customer (authenticated or not) in localStorage (for filling checkout fields)
export const customerInStorageAtom = atomWithStorage(
  'baltbaby_cart_customer',
  null
);

export const localeAtom = atom('et');
