import { gql } from '@apollo/client';
import { COMPARE_LIST_FIELDS } from 'graphql/fragments/compareList';

export const CREATE_COMPARE_LIST = gql`
  ${COMPARE_LIST_FIELDS}

  mutation CreateCompareList($productId: ID!) {
    createCompareList(input: { products: [$productId] }) {
      ...CompareListFields
    }
  }
`;
