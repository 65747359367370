import { gql } from '@apollo/client';

export const BLOG_POSTS = gql`
  query BlogPosts {
    blogPosts(sort: ["DESC"], allPosts: true) {
      items {
        title
        content
        short_content
        author {
          name
        }
        publish_time
        identifier
        featured_image
        categories {
          title
        }
      }
      total_count
    }
  }
`;
