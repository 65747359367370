import { gql } from '@apollo/client';

export const MERGE_CARTS = gql`
  mutation MergeCarts($sourceCart: String!, $destinationCart: String!) {
    mergeCarts(
      source_cart_id: $sourceCart
      destination_cart_id: $destinationCart
    ) {
      items {
        id
      }
    }
  }
`;
