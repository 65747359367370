import { gql } from '@apollo/client';
import { COMPARE_LIST_FIELDS } from 'graphql/fragments/compareList';

export const REMOVE_FROM_COMPARE_LIST = gql`
  ${COMPARE_LIST_FIELDS}

  mutation RemoveFromCompareList($uid: ID!, $productId: ID!) {
    removeProductsFromCompareList(
      input: { uid: $uid, products: [$productId] }
    ) {
      ...CompareListFields
    }
  }
`;
