import { gql } from '@apollo/client';

export const STATIC_PAGE = gql`
  query GetStaticPage($identifier: String) {
    cmsPage(identifier: $identifier) {
      title
      content
      meta_description
      meta_title
    }
  }
`;
