// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN, SENTRY_ENV } from 'global/constants';


Sentry.init({
  environment: SENTRY_ENV,
  dsn: "https://881eb987e4484433e56064875f1aaa72@o4507623797686272.ingest.de.sentry.io/4507623822721104",
  tracesSampleRate: 1.0,
  ignoreUrls: [
    /iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==/
  ]
});
