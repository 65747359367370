import { gql } from '@apollo/client';
import { COMPARE_LIST_FIELDS } from 'graphql/fragments/compareList';

export const ADD_TO_COMPARE_LIST = gql`
  ${COMPARE_LIST_FIELDS}

  mutation AddToCompareList($uid: ID!, $productId: ID!) {
    addProductsToCompareList(input: { uid: $uid, products: [$productId] }) {
      ...CompareListFields
    }
  }
`;
