import { gql } from '@apollo/client';
import { COMPARE_LIST_FIELDS } from '../fragments/compareList';

export const COMPARE_LIST = gql`
  ${COMPARE_LIST_FIELDS}

  query GetCompareList($uid: ID!) {
    compareList(uid: $uid) {
      ...CompareListFields
    }
  }
`;
