import { gql } from '@apollo/client';

export const CATEGORY_PRODUCTS_HOME = gql`
  query CategoryProducts(
    $id: String!
    $pageSize: Int
    $currentPage: Int
    $sortBy: ProductAttributeSortInput
    $price: FilterRangeTypeInput
    $brand: [String]
    $v__rv: [String] # $isofix: [String]
  ) # $kaal: [String]
  # $karu_kaal: [String]
  # $kandevoime: [String]
  # $kasutusaeg: [String]
  # $laius: [String]
  # $laste_jalgrattatoolid: [String]
  # $madrats_komplektis: [String]
  # $madratsi_moodud: [String]
  # $mudel: [String]
  # $otstarve: [String]
  # $rataste_arv: [String]

  {
    products(
      filter: {
        category_id: { eq: $id }
        price: $price
        brand: { in: $brand }
        v__rv: { in: $v__rv }
        kuva_avalehel: { eq: "1" }
      }
      search: ""
      sort: $sortBy
      currentPage: $currentPage
      pageSize: $pageSize
    ) {
      items {
        __typename
        name
        sku
        url_key
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        image {
          label
          url
        }
        media_gallery {
          label
          url
        }
        categories {
          name
          level
          url_key
        }
        rating_summary
        ... on ConfigurableProduct {
          variants {
            attributes {
              uid
              code
              label
            }
            product {
              sku
              image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
          }
        }
      }
      page_info {
        total_pages
      }
      total_count
    }
  }
`;
