import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation CreateOrder($cartId: String!) {
    placeOrder(input: { cart_id: $cartId }) {
      order {
        order_number
      }
    }
  }
`;
