import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: CustomerUpdateInput!) {
    updateCustomerV2(input: $input) {
      customer {
        firstname
        lastname
        email
        addresses {
          telephone
          street
          city
          region {
            region
            region_code
            region_id
          }
          postcode
          id
        }
        orders {
          items {
            id
            number
            order_date
            status
            total {
              grand_total {
                value
              }
            }
            carrier
            items {
              product_name
              product_sku
              product_url_key
            }
          }
          total_count
        }
        wishlists {
          id
          items_count
          items_v2 {
            items {
              id
              product {
                __typename
                sku
                name
                url_key
                price_range {
                  maximum_price {
                    discount {
                      amount_off
                      percent_off
                    }
                    final_price {
                      currency
                      value
                    }
                    regular_price {
                      currency
                      value
                    }
                  }
                }
                price {
                  regularPrice {
                    amount {
                      value
                    }
                  }
                }
                image {
                  url
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;
