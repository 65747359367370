import { gql } from '@apollo/client';

export const RELATED_PRODUCTS = gql`
  query GetProduct($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey } }) {
      items {
        related_products {
          __typename
          name
          sku
          url_key
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
          price {
            regularPrice {
              amount {
                value
              }
            }
          }
          image {
            url
            label
          }
          media_gallery {
            url
            label
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
          }
          description {
            html
          }
          short_description {
            html
          }
          rating_summary
        }
      }
    }
  }
`;
