import { gql } from '@apollo/client';

export const SET_BRAINTREE_PAYMENT_METHOD = gql`
  mutation SetBraintreePaymentMethod(
    $cartId: String!
    $paymentMethod: String!
    $nonce: String!
  ) {
    setPaymentMethodOnCart(
      input: {
        cart_id: $cartId
        payment_method: {
          code: $paymentMethod
          braintree: {
            payment_method_nonce: $nonce
            is_active_payment_token_enabler: false
          }
        }
      }
    ) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`;
