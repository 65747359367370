import { gql } from '@apollo/client';

export const CUSTOM_ATTRIBUTE_VALUES = gql`
  query CustomAttributeValues($id: String!) {
    products(filter: { category_id: { eq: $id } }) {
      aggregations {
        label
        attribute_code
        options {
          value
          label
        }
      }
    }
  }
`;
