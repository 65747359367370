import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { apolloClient } from 'graphql/apollo-client';
import { CREATE_NEW_CART, MERGE_CARTS } from 'graphql/mutations';
import { useAtomValue } from 'jotai/utils';
import { authAtom } from 'global/store';
import { AUTH_CART_INIT, CART_ID } from 'global/constants';
import createCartCookie from 'utils/createCartCookie';



export default function useMergeCarts() {
  const [newCart, setNewCart] = useState();
  const cookies = new Cookies();

  const id = cookies.get(CART_ID);
  const authCartInit = cookies.get(AUTH_CART_INIT);

  const { token: isAuth } = useAtomValue(authAtom);

  const [mergeCarts] = useMutation(MERGE_CARTS, {
    client: apolloClient,
    onCompleted: data => {
      createCartCookie(newCart);
      window.location.reload();

    },
    onError: err => {
      createCartCookie(newCart);
      window.location.reload();
    }
  });
  const [createCart] = useMutation(CREATE_NEW_CART, {
    client: apolloClient,
    onCompleted: data => {
      const cart_id = data?.createEmptyCart;
      setNewCart(cart_id);
      //CHECK IF CART HAS COUPON CODE
      if (cart_id && id !== cart_id) {
        mergeCarts({ variables: { sourceCart: id, destinationCart: cart_id } });
      }

    
    }
  });

  useEffect(() => {
    // If cart ID in cookies and user authenticated
    // but new cart not yet initiated
    if (isAuth && id && !authCartInit) {
      // Set 'baltbaby_auth_cart_init' cookie to expire in 1 day
      const expires = dayjs().add(1, 'd').toDate();
      // Set as cookie
      cookies.set(AUTH_CART_INIT, true, { path: '/', expires });

      // Init new cart
      createCart();
    }
  }, [isAuth, cookies]);
}
