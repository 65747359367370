import { useEffect } from 'react';
import packageJSON from '../package.json';

export default function useAppVersion() {
  const APP_VERSION = packageJSON.version;
  // Reload app if not latest version
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!localStorage.hasOwnProperty('baltbaby_app_version')) {
        localStorage.setItem('baltbaby_app_version', APP_VERSION);
      } else {
        const LOCAL_VERSION = localStorage.getItem('baltbaby_app_version');
        if (LOCAL_VERSION !== APP_VERSION) {
          localStorage.setItem('baltbaby_app_version', APP_VERSION);
          window.location.reload();
        }
      }
    }
  }, [APP_VERSION]);
}
