import { useEffect } from 'react';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { useMutation, useQuery } from '@apollo/client';
import { apolloClient } from 'graphql/apollo-client';
import { CART } from 'graphql/queries';
import { CREATE_NEW_CART } from 'graphql/mutations';
import { CART_ID } from 'global/constants';

export default function useCreateCart() {
  const cookies = new Cookies();
  const id = cookies.get(CART_ID);

  const [createCart] = useMutation(CREATE_NEW_CART, {
    client: apolloClient,
    onCompleted: data => {
      const cart_id = data?.createEmptyCart;
      // Set to expire in 1 day
      const expires = dayjs().add(1, 'd').toDate();
      // Set as cookie
      cookies.set(CART_ID, cart_id, { path: '/', expires });
    }
  });

  const { error } = useQuery(CART, { variables: { id }, client: apolloClient });

  const newCartConditions =
    !id || (error && error?.message.includes("isn't active"));

  useEffect(() => {
    // If no cart ID found in cookies or cart error, create new cart
    if (newCartConditions) createCart();
  }, [newCartConditions]);
}
