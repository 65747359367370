import { gql } from '@apollo/client';

export const PROVINCES = gql`
  query GetProvinces($id: String!) {
    country(id: $id) {
      available_regions {
        id
        code
        name
      }
    }
  }
`;
