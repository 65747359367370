import { gql } from '@apollo/client';
import { CART_FIELDS } from '../../fragments/cart';

export const ADD_CONF_PRODUCT_TO_CART = gql`
  ${CART_FIELDS}

  mutation AddConfProductToCart(
    $cartId: String!
    $sku: String!
    $selectedOptions: [ID]
  ) {
    addProductsToCart(
      cartId: $cartId
      cartItems: [
        { quantity: 1, sku: $sku, selected_options: $selectedOptions }
      ]
    ) {
      cart {
        ...CartFields
      }
    }
  }
`;
