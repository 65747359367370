import { gql } from '@apollo/client';
import { CART_FIELDS } from 'graphql/fragments/cart';

export const SET_GUEST_EMAIL = gql`
  ${CART_FIELDS}

  mutation SetGuestEmail($cartId: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
      cart {
        ...CartFields
      }
    }
  }
`;
