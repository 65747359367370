import { gql } from '@apollo/client';
import { COMPARE_LIST_FIELDS } from 'graphql/fragments/compareList';

export const ASSIGN_COMPARE_LIST_TO_CUSTOMER = gql`
  ${COMPARE_LIST_FIELDS}

  mutation AssignCompareListToCustomer($uid: ID!) {
    assignCompareListToCustomer(uid: $uid) {
      ...CompareListFields
    }
  }
`;
