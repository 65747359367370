import { gql } from '@apollo/client';

export const FEATURED_BRANDS = gql`
  query Brands {
    mpbrand(filter: { is_featured: { eq: "1" } }, currentPage: 1, pageSize: 6) {
      items {
        value
        image
        url_key
        option_id
        is_featured
      }
    }
  }
`;
