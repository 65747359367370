// Node environment (development, staging or production)
const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;

// Magento endpoints
export const MAGENTO_URI = process.env.NEXT_PUBLIC_MAGENTO_URI;

export const API_URL_GRAPHQL = `${MAGENTO_URI}/graphql`;
export const API_URL_REST = `${MAGENTO_URI}/rest`;

// Client endpoints
export const CLIENT_DEV = process.env.NEXT_PUBLIC_CLIENT_DEV;
export const CLIENT_TEST = process.env.NEXT_PUBLIC_CLIENT_TEST;
export const CLIENT_PRODUCTION = process.env.NEXT_PUBLIC_CLIENT_PRODUCTION;
export const CLIENT =
  NODE_ENV === 'development'
    ? CLIENT_DEV
    : NODE_ENV === 'staging'
    ? CLIENT_TEST
    : CLIENT_PRODUCTION;

// Cookie names
export const USER_TOKEN = 'baltbaby_user_token';
export const USER_SOCIAL_AUTH = 'baltbaby_user_social';
export const CART_ID = 'baltbaby_cart_id';
export const AUTH_CART_INIT = 'baltbaby_auth_cart_init';

// Admin credentials
export const ADMIN_USERNAME = process.env.NEXT_PUBLIC_MAGENTO_ADMIN_USERNAME;
export const ADMIN_PASSWORD = process.env.NEXT_PUBLIC_MAGENTO_ADMIN_PASSWORD;

// BRAINTREE
export const BRAINTREE_MERCHANT_ID =
  process.env.NEXT_PUBLIC_BRAINTREE_MERCHANT_ID;
export const BRAINTREE_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_BRAINTREE_PUBLIC_KEY;
export const BRAINTREE_PRIVATE_KEY =
  process.env.NEXT_PUBLIC_BRAINTREE_PRIVATE_KEY;

// PAYPAL
export const PAYPAL_SANDBOX_CLIENT_ID =
  process.env.NEXT_PUBLIC_PAYPAL_SANDBOX_CLIENT_ID;
export const PAYPAL_SANDBOX_CLIENT_SECRET =
  process.env.NEXT_PUBLIC_PAYPAL_SANDBOX_CLIENT_SECRET;

// ESTO
export const ESTO_SHOP_ID = process.env.NEXT_PUBLIC_ESTO_SHOP_ID;
export const ESTO_API_KEY = process.env.NEXT_PUBLIC_ESTO_API_KEY;

// LIISI
export const LIISI_TEST_URL = process.env.NEXT_PUBLIC_LIISI_TEST_URL;
export const LIISI_LIVE_URL = process.env.NEXT_PUBLIC_LIISI_LIVE_URL;
export const LIISI_TEST_ID = process.env.NEXT_PUBLIC_LIISI_TEST_ID;
export const LIISI_LIVE_ID = process.env.NEXT_PUBLIC_LIISI_LIVE_ID;

//MODENA
export const MODENA_API_URL = process.env.NEXT_PUBLIC_MODENA_API_URL;

// FACEBOOK
const FACEBOOK_TEST_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_TEST_APP_ID;
const FACEBOOK_LIVE_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
const FACEBOOK_TEST_APP_SECRET =
  process.env.NEXT_PUBLIC_FACEBOOK_TEST_APP_SECRET;
const FACEBOOK_LIVE_APP_SECRET = process.env.NEXT_PUBLIC_FACEBOOK_APP_SECRET;

export const FACEBOOK_APP_ID =
  NODE_ENV === 'development' ? FACEBOOK_TEST_APP_ID : FACEBOOK_LIVE_APP_ID;
export const FACEBOOK_APP_SECRET =
  NODE_ENV === 'development'
    ? FACEBOOK_TEST_APP_SECRET
    : FACEBOOK_LIVE_APP_SECRET;

export const FACEBOOK_PAGE_ID = process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID;
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

// GOOGLE
export const GOOGLE_APP_ID = process.env.NEXT_PUBLIC_GOOGLE_APP_ID;
export const GOOGLE_APP_SECRET = process.env.NEXT_PUBLIC_GOOGLE_APP_SECRET;
export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
export const GOOGLE_SHEETS_API_URL =
  process.env.NEXT_PUBLIC_GOOGLE_SHEETS_API_URL;

// MAILCHIMP
export const MAILCHIMP_API_KEY = process.env.NEXT_PUBLIC_MAILCHIMP_API_KEY;
export const MAILCHIMP_LIST_ID = process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID;

// SENTRY
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;
// GITHUB TOKEN
export const GITHUB_TOKEN = process.env.NEXT_GITHUB_TOKEN;
