import {
  BabyFace,
  Trolley,
  Cradle,
  ChildSeat,
  Outdoors,
  Clothes
} from '@/UI/Icons/Categories';
import { Profile, ShoppingHistory } from '@/UI/Icons/Account';
import { Heart } from '@/UI/Icons';
import categoriesJson from '../data/categories.json'


export const shippingOptions = [
    {
    label: 'Ise järgi',
    labelEN: 'To store',
    type: 'Riia 142, Tartu Novapolis',
    typeEN: 'Riia 142, Tartu Novapolis',
    carrier_code: 'flatrate',
    method_code: 'flatrate'
  },
  {
    label: 'Omniva',
    labelEN: 'Omniva',
    typeEN: 'Parcel Point',
    type: 'Pakiautomaat',
    carrier_code: 'omnivaeeparcelpoint',
    method_code: 'omnivaeeparcelpoint'
  },
  {
    label: 'Itella Smartpost',
    labelEN: 'Itella Smartpost',
    type: 'Pakiautomaat',
    typeEN: 'Parcel Point',
    carrier_code: 'smartpostestonia',
    method_code: 'smartpostestonia'
  },
  {
    label: 'DPD Pickup',
    labelEN: 'DPD Pickup',
    type: 'Pakiautomaat',
    typeEN: 'Parcel Point',
    carrier_code: 'dpd',
    method_code: 'pickup'
  },
  { label: 'DPD', labelEN: 'DPD', type: 'Kuller', typeEN: 'Delivery', carrier_code: 'dpd', method_code: 'classic' }
];

export const sortOptions = {
  et: {
    content: [
      { value: { bestsellers: 'DESC' }, label: 'Populaarsemad' },
      // { value: { new: 'DESC' }, label: 'Uuemad' },
      { value: { price_asc: 'ASC' }, label: 'Hind (soodsam)' },
      { value: { price_desc: 'DESC' }, label: 'Hind (kallim)' }
    ]
  },
  en: {
    content: [
      { value: { bestsellers: 'DESC' }, label: 'Most popular' },
      // { value: { new: 'DESC' }, label: 'Uuemad' },
      { value: { price_asc: 'ASC' }, label: 'Price (cheaper)' },
      { value: { price_desc: 'DESC' }, label: 'Price (pricier)' }
    ]
  },
}

export const priceRanges = [
  {
    name: '0-99',
    label: '0,00 € - 99,99 €',
    value: { to: '99.99' }
  },
  {
    name: '100-199',
    label: '100,00 € - 199,99 €',
    value: { from: '100', to: '199.99' }
  },
  { name: '200', label: '> 200,00 €', value: { from: '200' } }
];

export const accountItems = [
  { path: '/konto', title: 'andmed', titleEN: 'details', icon: Profile },
  { path: '/konto/tellimused', title: 'tellimused',titleEN: 'orders', icon: ShoppingHistory },
  { path: '/konto/lemmikud', title: 'lemmikud',titleEN: 'favourites', icon: <Heart /> }
];

export const footerItems = {
  et: {
    content: [
      { title: 'Kohaletoimetamine', path: 'kohaletoimetamine' },
      { title: 'Kasutustingimused', path: 'kasutustingimused' },
      { title: 'Privaatsuspoliitika', path: 'privaatsuspoliitika' },
      { title: 'BuyPlan', path: 'buyplan' },
      { title: 'Tagastamine ja vahetus', path: 'tagastamine-ja-vahetus' },
      { title: 'Ettevõttest', path: 'ettevottest' },
      { title: 'Makseviisid', path: 'makseviisid' },
      { title: 'Blogi', path: 'blogi' }
    ]
  },
  en: {
    content: [
      { title: 'Delivery', path: 'delivery' },
      { title: 'Terms & Conditions', path: 'terms-of-use' },
      { title: 'Privacy Policy', path: 'privacy-terms' },
      { title: 'Returns', path: 'return-policy' },
      { title: 'About us', path: 'about-us' },
      { title: 'Payment Methods', path: 'payment-methods' },
      { title: 'Blog', path: 'blogi' }
    ]
  },
}

export const searchRecommendations = [
  {
    title: 'Thule Urban Glide',
    path: ''
  },
  {
    title: 'Thule Urban Glide 2',
    path: ''
  },
  {
    title: 'Thule Sleek',
    path: ''
  },
  {
    title: 'Thule Urban',
    path: ''
  },
  {
    title: 'Thule Sleek joogitopsi hoidja',
    path: ''
  },
  {
    title: 'Thule Stroller',
    path: ''
  },
  {
    title: 'Thule Glide',
    path: ''
  },
  {
    title: 'Thule soojakott',
    path: ''
  },
  {
    title: 'Thule Spring',
    path: ''
  }
];

const product = {
  name: 'Mountain Buggy Urban Jungle jalutuskäru',
  image: {
    url: 'https://baltbaby.com/pub/media/catalog/product/cache/266d936cdb1c90395d5033c50a695cf2/3/3/3384_3570_601dc5480cf458_86782072_ThuleUG2BlackOnBlack_jpg_3.jpg',
    label: 'Image'
  },
  price_range: {
    maximum_price: {
      discount: {
        amount_off: 100,
        percent_off: 12.52
      },
      final_price: {
        currency: 'EUR',
        value: 699
      },
      regular_price: {
        currency: 'EUR',
        value: 799
      }
    }
  }
};

export const products = Array(15).fill(product);

export const categoryIcons = [
  { icon: Trolley, category: 'Lapsevankrid ja kärud', categoryEN: 'Strollers' },
  { icon: ChildSeat, category: 'Laste turvavarustus', categoryEN: 'Safety Gear' },
  {
    icon: Clothes,
    category: 'Riided ja jalanõud',
    categoryEN: 'Clothes and shoes'
  },
  { icon: Cradle, category: 'Lastetuba', categoryEN: 'Nursery' }, 
  { icon: BabyFace, category: 'Beebikaubad', categoryEN: 'Baby products' },
  {
    icon: Outdoors,
    category: 'Õues ja reisil',
    categoryEN: 'For travelling and outside' 
  }
];

export const countryOptions = [
  { label: 'Bulgaaria', value: 'Bulgaaria', id: 'BG' },
  { label: 'Eesti', value: 'Eesti', id: 'EE' },
  { label: 'Hispaania', value: 'Hispaania', id: 'ES' },
  { label: 'Itaalia', value: 'Itaalia', id: 'IT' },
  { label: 'Läti', value: 'Läti', id: 'LV' },
  { label: 'Soome', value: 'Soome', id: 'FI' }
];

export const omnivaCounties = [
  'Harju maakond',
  'Hiiu maakond',
  'Ida-Viru maakond',
  'Jõgeva maakond',
  'Järva maakond',
  'Lääne maakond',
  'Lääne-Viru maakond',
  'Põlva maakond',
  'Pärnu maakond',
  'Rapla maakond',
  'Saare maakond',
  'Tartu maakond',
  'Valga maakond',
  'Viljandi maakond',
  'Võru maakond'
];

export const smartpostCounties = [
  'Harjumaa',
  'Hiiumaa',
  'Ida-Virumaa',
  'Jõgevamaa',
  'Järvamaa',
  'Läänemaa',
  'Lääne-Virumaa',
  'Põlvamaa',
  'Pärnumaa',
  'Raplamaa',
  'Saaremaa',
  'Tartumaa',
  'Valgamaa',
  'Viljandimaa',
  'Võrumaa'
];

export const categories = categoriesJson