import { gql } from '@apollo/client';
import { CART_FIELDS } from 'graphql/fragments/cart';

export const REMOVE_COUPON = gql`
  ${CART_FIELDS}
  
  mutation RemoveCoupon($cartId: String!) {
    removeCouponFromCart(input: { cart_id: $cartId}) {
      cart {
        ...CartFields
      }
    }
  }
`;
