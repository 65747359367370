import { gql } from '@apollo/client';

export const COMPARE_LIST_FIELDS = gql`
  fragment CompareListFields on CompareList {
    uid
    item_count
    attributes {
      code
      label
    }
    items {
      uid
      product {
        sku
        name
        description {
          html
        }
      }
    }
  }
`;
