import { gql } from '@apollo/client';

export const GET_VARIATION_IMAGE = gql`
  query GetProduct($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        ... on ConfigurableProduct {
            variants {
              product {
                uid
                image {
                  url
                }
              }
            }
          }
      }
    }
  }
`;
