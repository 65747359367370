// "Beebile ja emale"

export const BabyFace = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.384"
    height="21.045"
    viewBox="0 0 25.384 21.045"
  >
    <g
      id="Group_459"
      data-name="Group 459"
      transform="translate(-1294.189 -107.682)"
    >
      <g
        id="Group_9"
        data-name="Group 9"
        transform="translate(1300.622 107.682)"
      >
        <path
          id="Path_11"
          data-name="Path 11"
          d="M1344.668,110.957a9.434,9.434,0,0,0-11.664-.342l-.644-.867a10.513,10.513,0,0,1,13,.381Z"
          transform="translate(-1332.36 -107.682)"
        />
      </g>
      <g id="Group_458" data-name="Group 458">
        <g
          id="Group_8"
          data-name="Group 8"
          transform="translate(1294.189 109.742)"
        >
          <path
            id="Path_10"
            data-name="Path 10"
            d="M1306.881,138.889A10.5,10.5,0,0,1,1296.6,130.6h-.078a2.332,2.332,0,1,1,0-4.665h.113a10.513,10.513,0,0,1,3.984-6.027l.643.868a9.431,9.431,0,0,0-3.661,5.8l-.084.438h-1a1.252,1.252,0,1,0,0,2.5h.978l.077.449a9.44,9.44,0,0,0,18.609,0l.077-.449h.978a1.252,1.252,0,1,0,0-2.5h-1l-.084-.438a9.431,9.431,0,0,0-3.661-5.8l.643-.868a10.514,10.514,0,0,1,3.984,6.027h.113a2.332,2.332,0,0,1,0,4.665h-.078A10.5,10.5,0,0,1,1306.881,138.889Z"
            transform="translate(-1294.189 -119.904)"
          />
        </g>
        <g
          id="Group_13"
          data-name="Group 13"
          transform="translate(1303.153 108.26)"
        >
          <g
            id="Group_10"
            data-name="Group 10"
            transform="translate(3.174 0.344)"
          >
            <path
              id="Path_12"
              data-name="Path 12"
              d="M1367.939,117.889c-1.86-1.319-1.8-3.754-1.681-4.74l1.073.125c-.092.794-.156,2.749,1.232,3.734Z"
              transform="translate(-1366.21 -113.149)"
            />
          </g>
          <g id="Group_11" data-name="Group 11" transform="translate(0 0.026)">
            <path
              id="Path_13"
              data-name="Path 13"
              d="M1348.237,115.661l-.862-.651a6.064,6.064,0,0,0,1.317-3.719l1.08-.023A7.166,7.166,0,0,1,1348.237,115.661Z"
              transform="translate(-1347.375 -111.267)"
            />
          </g>
          <g id="Group_12" data-name="Group 12" transform="translate(5.661 0)">
            <path
              id="Path_14"
              data-name="Path 14"
              d="M1382.461,115.717a8.5,8.5,0,0,1-1.494-4.531l1.077-.076a7.644,7.644,0,0,0,1.237,3.905Z"
              transform="translate(-1380.967 -111.11)"
            />
          </g>
        </g>
        <path
          id="Path_15"
          data-name="Path 15"
          d="M1350.8,195.858a5.341,5.341,0,0,1-3.79-1.567l.764-.764a4.285,4.285,0,0,0,6.052,0l.764.764A5.343,5.343,0,0,1,1350.8,195.858Z"
          transform="translate(-43.919 -71.377)"
        />
        <g
          id="Group_17"
          data-name="Group 17"
          transform="translate(1300.633 116.756)"
        >
          <g id="Group_15" data-name="Group 15" transform="translate(7.816 0)">
            <path
              id="Path_16"
              data-name="Path 16"
              d="M1381.143,163.253a3.3,3.3,0,0,1-2.34-.968l.764-.764a2.231,2.231,0,0,0,3.152,0l.764.764A3.3,3.3,0,0,1,1381.143,163.253Z"
              transform="translate(-1378.803 -161.522)"
            />
          </g>
          <g id="Group_16" data-name="Group 16" transform="translate(0 0)">
            <path
              id="Path_17"
              data-name="Path 17"
              d="M1334.764,163.253a3.3,3.3,0,0,1-2.34-.968l.764-.764a2.233,2.233,0,0,0,3.152,0l.764.764A3.3,3.3,0,0,1,1334.764,163.253Z"
              transform="translate(-1332.424 -161.522)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Trolley = (
  <svg
    id="Group_41"
    data-name="Group 41"
    xmlns="http://www.w3.org/2000/svg"
    width="23.406"
    height="23.552"
    viewBox="0 0 23.406 23.552"
  >
    <g id="Group_30" data-name="Group 30" transform="translate(8.001 3.149)">
      <path
        id="Path_25"
        data-name="Path 25"
        d="M2004.024,1386.092h11.194a.5.5,0,0,0,.356-.854l-14.4-14.5a.5.5,0,0,0-.857.353v11.27A3.725,3.725,0,0,0,2004.024,1386.092Zm9.99-1h-9.99a2.722,2.722,0,0,1-2.707-2.729v-10.055Z"
        transform="translate(-2000.314 -1370.589)"
      />
    </g>
    <g id="Group_31" data-name="Group 31" transform="translate(11.652 3.083)">
      <path
        id="Path_26"
        data-name="Path 26"
        d="M2053.274,1374.121a.5.5,0,0,0,.355-.148,2.239,2.239,0,0,0,0-3.15,2.209,2.209,0,0,0-3.138,0,.5.5,0,1,0,.711.706,1.221,1.221,0,0,1,1.717,1.738.5.5,0,0,0,.355.854Z"
        transform="translate(-2050.345 -1370.169)"
      />
    </g>
    <g id="Group_32" data-name="Group 32" transform="translate(16.147 8.115)">
      <path
        id="Path_27"
        data-name="Path 27"
        d="M2009.429,1409.207a.5.5,0,0,0,.356-.854l-5.821-5.86a.5.5,0,1,0-.711.706l5.821,5.86A.5.5,0,0,0,2009.429,1409.207Z"
        transform="translate(-2003.107 -1402.345)"
      />
    </g>
    <g id="Group_35" data-name="Group 35" transform="translate(10.066 19.721)">
      <g id="Group_33" data-name="Group 33" transform="translate(0 0)">
        <path
          id="Path_28"
          data-name="Path 28"
          d="M2063.157,1480.4a1.915,1.915,0,1,0-1.906-1.915A1.913,1.913,0,0,0,2063.157,1480.4Zm0-2.828a.913.913,0,1,1-.9.913A.909.909,0,0,1,2063.157,1477.573Z"
          transform="translate(-2061.251 -1476.571)"
        />
      </g>
      <g id="Group_34" data-name="Group 34" transform="translate(7.439 0)">
        <path
          id="Path_29"
          data-name="Path 29"
          d="M2015.579,1480.4a1.915,1.915,0,1,0-1.906-1.915A1.913,1.913,0,0,0,2015.579,1480.4Zm0-2.828a.913.913,0,1,1-.9.913A.91.91,0,0,1,2015.579,1477.573Z"
          transform="translate(-2013.673 -1476.571)"
        />
      </g>
    </g>
    <g id="Group_39" data-name="Group 39" transform="translate(8.503 18.151)">
      <g id="Group_36" data-name="Group 36" transform="translate(0 2.13)">
        <rect
          id="Rectangle_84"
          data-name="Rectangle 84"
          width="2.185"
          height="1.002"
        />
      </g>
      <g id="Group_37" data-name="Group 37" transform="translate(4.754 2.13)">
        <rect
          id="Rectangle_85"
          data-name="Rectangle 85"
          width="4.896"
          height="1.002"
        />
      </g>
      <g id="Group_38" data-name="Group 38" transform="translate(12.217 0)">
        <path
          id="Path_30"
          data-name="Path 30"
          d="M2000.313,1469.661h2.185a.5.5,0,0,0,.5-.5v-2.631h-1v2.13h-1.684Z"
          transform="translate(-2000.313 -1466.529)"
        />
      </g>
    </g>
    <g id="Group_40" data-name="Group 40" transform="translate(0 0)">
      <path
        id="Path_31"
        data-name="Path 31"
        d="M2094.064,1354.525l2.552-2.569a1.729,1.729,0,0,1,2.4,0l2.484,2.5.71-.706-2.483-2.5a2.691,2.691,0,0,0-3.823,0l-2.551,2.569Z"
        transform="translate(-2093.353 -1350.453)"
      />
    </g>
  </svg>
);

export const Cradle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.326"
    height="24.508"
    viewBox="0 0 26.326 24.508"
  >
    <g
      id="Group_68"
      data-name="Group 68"
      transform="translate(-1305.126 -1356.502)"
    >
      <g
        id="Group_66"
        data-name="Group 66"
        transform="translate(1306.136 1356.502)"
      >
        <g
          id="Group_54"
          data-name="Group 54"
          transform="translate(1.874 15.856)"
        >
          <rect
            id="Rectangle_88"
            data-name="Rectangle 88"
            width="20.531"
            height="1.134"
          />
        </g>
        <g
          id="Group_55"
          data-name="Group 55"
          transform="translate(1.307 6.731)"
        >
          <path
            id="Path_38"
            data-name="Path 38"
            d="M1339.879,1408.4h-1.134v-12.74h-19.4v12.74h-1.134V1394.53h21.665Z"
            transform="translate(-1318.213 -1394.53)"
          />
        </g>
        <g
          id="Group_56"
          data-name="Group 56"
          transform="translate(1.874 12.206)"
        >
          <rect
            id="Rectangle_89"
            data-name="Rectangle 89"
            width="20.531"
            height="1.134"
          />
        </g>
        <g
          id="Group_57"
          data-name="Group 57"
          transform="translate(18.416 7.298)"
        >
          <rect
            id="Rectangle_90"
            data-name="Rectangle 90"
            width="1.134"
            height="5.475"
          />
        </g>
        <g
          id="Group_58"
          data-name="Group 58"
          transform="translate(14.994 7.298)"
        >
          <rect
            id="Rectangle_91"
            data-name="Rectangle 91"
            width="1.134"
            height="5.475"
          />
        </g>
        <g
          id="Group_59"
          data-name="Group 59"
          transform="translate(11.572 7.298)"
        >
          <rect
            id="Rectangle_92"
            data-name="Rectangle 92"
            width="1.134"
            height="5.475"
          />
        </g>
        <g
          id="Group_60"
          data-name="Group 60"
          transform="translate(8.151 7.298)"
        >
          <rect
            id="Rectangle_93"
            data-name="Rectangle 93"
            width="1.134"
            height="5.475"
          />
        </g>
        <g
          id="Group_61"
          data-name="Group 61"
          transform="translate(4.729 7.298)"
        >
          <rect
            id="Rectangle_94"
            data-name="Rectangle 94"
            width="1.134"
            height="5.475"
          />
        </g>
        <g
          id="Group_62"
          data-name="Group 62"
          transform="translate(21.838 3.181)"
        >
          <rect
            id="Rectangle_95"
            data-name="Rectangle 95"
            width="1.134"
            height="4.117"
          />
        </g>
        <g
          id="Group_63"
          data-name="Group 63"
          transform="translate(1.307 3.181)"
        >
          <rect
            id="Rectangle_96"
            data-name="Rectangle 96"
            width="1.134"
            height="4.117"
          />
        </g>
        <g id="Group_64" data-name="Group 64" transform="translate(20.558 0)">
          <path
            id="Path_39"
            data-name="Path 39"
            d="M1428.856,1360.25a1.874,1.874,0,1,1,1.874-1.874A1.876,1.876,0,0,1,1428.856,1360.25Zm0-2.614a.74.74,0,1,0,.74.74A.741.741,0,0,0,1428.856,1357.636Z"
            transform="translate(-1426.982 -1356.502)"
          />
        </g>
        <g id="Group_65" data-name="Group 65" transform="translate(0 0)">
          <path
            id="Path_40"
            data-name="Path 40"
            d="M1312.7,1360.25a1.874,1.874,0,1,1,1.874-1.874A1.876,1.876,0,0,1,1312.7,1360.25Zm0-2.614a.74.74,0,1,0,.74.74A.741.741,0,0,0,1312.7,1357.636Z"
            transform="translate(-1310.831 -1356.502)"
          />
        </g>
      </g>
      <g
        id="Group_67"
        data-name="Group 67"
        transform="translate(1305.126 1374.764)"
      >
        <path
          id="Path_41"
          data-name="Path 41"
          d="M1318.289,1465.929a18.558,18.558,0,0,1-13.163-5.444l.8-.8a17.5,17.5,0,0,0,24.722,0l.8.8A18.558,18.558,0,0,1,1318.289,1465.929Z"
          transform="translate(-1305.126 -1459.683)"
        />
      </g>
    </g>
  </svg>
);

export const ChildSeat = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.886"
    height="16.074"
    viewBox="0 0 22.886 16.074"
  >
    <g
      id="Group_84"
      data-name="Group 84"
      transform="translate(-613.837 -1028.48)"
    >
      <g
        id="Group_81"
        data-name="Group 81"
        transform="translate(622.627 1033.865)"
      >
        <path
          id="Path_56"
          data-name="Path 56"
          d="M668.283,1065.511a2.652,2.652,0,1,1,2.652-2.652A2.655,2.655,0,0,1,668.283,1065.511Zm0-4.217a1.565,1.565,0,1,0,1.565,1.564A1.567,1.567,0,0,0,668.283,1061.294Z"
          transform="translate(-665.63 -1060.206)"
        />
      </g>
      <g
        id="Group_82"
        data-name="Group 82"
        transform="translate(624.736 1028.48)"
      >
        <path
          id="Path_57"
          data-name="Path 57"
          d="M690.041,1037.061h-9.878v-.544a1.567,1.567,0,0,0-1.565-1.565h-.544v-6.473H682a8.046,8.046,0,0,1,8.037,8.037Zm-8.847-1.088h7.738a6.959,6.959,0,0,0-6.928-6.405h-2.862v4.353A2.661,2.661,0,0,1,681.194,1035.973Z"
          transform="translate(-678.054 -1028.48)"
        />
      </g>
      <g
        id="Group_83"
        data-name="Group 83"
        transform="translate(613.837 1035.973)"
      >
        <path
          id="Path_58"
          data-name="Path 58"
          d="M632.149,1081.21H618.411a4.579,4.579,0,0,1-4.574-4.575v-4.007h9.878v.544a1.565,1.565,0,0,0,3.13,0v-.544h9.878v4.007A4.579,4.579,0,0,1,632.149,1081.21Zm-17.224-7.494v2.919a3.491,3.491,0,0,0,3.486,3.487h13.738a3.491,3.491,0,0,0,3.486-3.487v-2.919h-7.759a2.653,2.653,0,0,1-5.193,0Z"
          transform="translate(-613.837 -1072.629)"
        />
      </g>
    </g>
  </svg>
);

export const Outdoors = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.372"
    height="20.373"
    viewBox="0 0 20.372 20.373"
  >
    <g
      id="Group_86"
      data-name="Group 86"
      transform="translate(-395.77 -321.429)"
    >
      <path
        id="Path_60"
        data-name="Path 60"
        d="M410.862,340.46a3.94,3.94,0,1,1,3.94-3.94A3.944,3.944,0,0,1,410.862,340.46Zm0-6.927a2.988,2.988,0,1,0,2.988,2.988A2.991,2.991,0,0,0,410.862,333.533Z"
        transform="translate(-4.906 -4.906)"
        fill="#161615"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M413.582,325.9a.476.476,0,0,1-.476-.477v-3.52a.476.476,0,0,1,.952,0v3.52A.476.476,0,0,1,413.582,325.9Z"
        transform="translate(-7.626 0)"
        fill="#161615"
      />
      <path
        id="Path_62"
        data-name="Path 62"
        d="M419.107,327.752a.476.476,0,0,1-.412-.714l1.76-3.049a.476.476,0,1,1,.824.476l-1.76,3.049A.476.476,0,0,1,419.107,327.752Z"
        transform="translate(-10.057 -1.022)"
        fill="#161615"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M423.153,332.809a.476.476,0,0,1-.239-.889l3.049-1.76a.476.476,0,0,1,.476.824l-3.049,1.76A.476.476,0,0,1,423.153,332.809Z"
        transform="translate(-11.836 -3.813)"
        fill="#161615"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M428.153,339.717h-3.52a.476.476,0,1,1,0-.952h3.52a.476.476,0,1,1,0,.952Z"
        transform="translate(-12.487 -7.626)"
        fill="#161615"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M426.2,347a.473.473,0,0,1-.237-.064l-3.049-1.76a.476.476,0,0,1,.476-.824l3.049,1.76a.476.476,0,0,1-.239.888Z"
        transform="translate(-11.836 -10.056)"
        fill="#161615"
      />
      <path
        id="Path_66"
        data-name="Path 66"
        d="M420.868,352.336a.476.476,0,0,1-.413-.239l-1.76-3.049a.476.476,0,1,1,.824-.476l1.76,3.049a.476.476,0,0,1-.174.65A.47.47,0,0,1,420.868,352.336Z"
        transform="translate(-10.057 -11.836)"
        fill="#161615"
      />
      <path
        id="Path_67"
        data-name="Path 67"
        d="M413.582,354.288a.476.476,0,0,1-.476-.476v-3.521a.476.476,0,0,1,.952,0v3.521A.476.476,0,0,1,413.582,354.288Z"
        transform="translate(-7.626 -12.487)"
        fill="#161615"
      />
      <path
        id="Path_68"
        data-name="Path 68"
        d="M404.914,352.336a.471.471,0,0,1-.238-.064.476.476,0,0,1-.174-.65l1.76-3.049a.476.476,0,1,1,.824.476l-1.76,3.049A.475.475,0,0,1,404.914,352.336Z"
        transform="translate(-3.813 -11.836)"
        fill="#161615"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M398.569,347a.476.476,0,0,1-.239-.888l3.049-1.76a.476.476,0,0,1,.476.824l-3.049,1.76A.476.476,0,0,1,398.569,347Z"
        transform="translate(-1.022 -10.056)"
        fill="#161615"
      />
      <path
        id="Path_70"
        data-name="Path 70"
        d="M399.767,339.717h-3.521a.476.476,0,1,1,0-.952h3.521a.476.476,0,1,1,0,.952Z"
        transform="translate(0 -7.626)"
        fill="#161615"
      />
      <path
        id="Path_71"
        data-name="Path 71"
        d="M401.617,332.809a.476.476,0,0,1-.237-.064l-3.049-1.76a.476.476,0,1,1,.477-.824l3.049,1.76a.476.476,0,0,1-.239.889Z"
        transform="translate(-1.022 -3.813)"
        fill="#161615"
      />
      <path
        id="Path_72"
        data-name="Path 72"
        d="M406.675,327.752a.475.475,0,0,1-.413-.238l-1.76-3.049a.476.476,0,0,1,.824-.476l1.76,3.049a.476.476,0,0,1-.412.714Z"
        transform="translate(-3.813 -1.022)"
        fill="#161615"
      />
    </g>
  </svg>
);

export const Clothes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.176"
    height="18.001"
    viewBox="0 0 18.176 18.001"
  >
    <g
      id="Group_126"
      data-name="Group 126"
      transform="translate(-1649.511 -397.964)"
    >
      <g
        id="Group_125"
        data-name="Group 125"
        transform="translate(1649.511 397.964)"
      >
        <path
          id="Path_83"
          data-name="Path 83"
          d="M1660.407,415.965h-3.825v-.383a3.513,3.513,0,0,0-3.469-3.507l-.373-.009v-8.907l-2.083.551-1.145-4.222,5.807-1.523h.484v.383a2.8,2.8,0,0,0,5.595,0v-.383l.582.013,5.709,1.511-1.146,4.222-2.082-.551v8.929l-.455-.016h0a3.546,3.546,0,0,0-3.593,3.51Zm-3.076-.766h2.327a4.311,4.311,0,0,1,4.035-3.887v-9.149l2.308.611.745-2.743-4.614-1.221a3.569,3.569,0,0,1-7.066,0l-4.614,1.221.745,2.743,2.309-.611v9.171A4.269,4.269,0,0,1,1657.331,415.2Z"
          transform="translate(-1649.511 -397.964)"
        />
      </g>
    </g>
  </svg>
);
