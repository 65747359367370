import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function usePageView() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = url => {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'pageview',
          page: url
        });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, []);
}
