export const WorkSpace = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.585"
    height="23.677"
    viewBox="0 0 23.585 23.677"
  >
    <g
      id="Group_229"
      data-name="Group 229"
      transform="translate(-1273 -816.323)"
    >
      <path
        id="Path_158"
        data-name="Path 158"
        d="M1293.5,829.5v8a2,2,0,0,1-2,2h-16a2,2,0,0,1-2-2v-16a2,2,0,0,1,2-2h7.625"
        fill="none"
        stroke="#163567"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g id="Group_228" data-name="Group 228">
        <path
          id="Path_159"
          data-name="Path 159"
          d="M1286.5,830.5h-4v-4l9.293-9.293a1,1,0,0,1,1.414,0l2.586,2.586a1,1,0,0,1,0,1.414Z"
          fill="none"
          stroke="#163567"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_114"
          data-name="Line 114"
          x2="4"
          y2="4"
          transform="translate(1289.5 819.5)"
          fill="none"
          stroke="#163567"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_115"
          data-name="Line 115"
          x1="1"
          y2="1"
          transform="translate(1281.5 830.5)"
          fill="none"
          stroke="#163567"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export const Profile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.364"
    height="21"
    viewBox="0 0 18.364 21"
  >
    <g
      id="Group_312"
      data-name="Group 312"
      transform="translate(-122.05 -80.14)"
    >
      <path
        id="Path_195"
        data-name="Path 195"
        d="M131.232,87.64a3.5,3.5,0,1,1,3.5-3.5A3.505,3.505,0,0,1,131.232,87.64Zm0-6a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,131.232,81.64Z"
        fill="#52c1d3"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Path_196"
        data-name="Path 196"
        d="M131.232,100.64c-5.436,0-8.682-1.869-8.682-5,0-2.928.623-5,8.682-5s8.682,2.072,8.682,5C139.914,98.771,136.668,100.64,131.232,100.64Zm0-9c-7.682,0-7.682,1.765-7.682,4,0,3.711,5.88,4,7.682,4s7.682-.289,7.682-4C138.914,93.4,138.914,91.64,131.232,91.64Z"
        fill="#52c1d3"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const ShoppingHistory = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.724"
    height="18.648"
    viewBox="0 0 14.724 18.648"
  >
    <g
      id="Group_311"
      data-name="Group 311"
      transform="translate(-771.057 -864)"
    >
      <path
        id="Path_193"
        data-name="Path 193"
        d="M776.5,870.639v-3.069a3.069,3.069,0,0,1,3.069-3.069h0a3.069,3.069,0,0,1,3.069,3.069v3.069"
        transform="translate(-1.15)"
        fill="#fff"
        stroke="#163567"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_194"
        data-name="Path 194"
        d="M784.6,870.224l.682,12.277a.767.767,0,0,1-.766.81h-12.19a.767.767,0,0,1-.766-.81l.682-12.277a.767.767,0,0,1,.766-.724h10.825A.767.767,0,0,1,784.6,870.224Z"
        transform="translate(0 -1.163)"
        fill="#fff"
        stroke="#163567"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const LogOut = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.854"
    height="17.59"
    viewBox="0 0 12.854 17.59"
  >
    <g
      id="Group_326"
      data-name="Group 326"
      transform="translate(-293.49 209.59) rotate(-90)"
    >
      <g
        id="Group_325"
        data-name="Group 325"
        transform="translate(197.024 299.057)"
      >
        <line
          id="Line_134"
          data-name="Line 134"
          x1="3.77"
          y2="3.77"
          transform="translate(3.77 0)"
          fill="none"
          stroke="#c1c1c1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_135"
          data-name="Line 135"
          x2="3.77"
          y2="3.77"
          transform="translate(0 0)"
          fill="none"
          stroke="#c1c1c1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <path
        id="Path_198"
        data-name="Path 198"
        d="M209.09,305.5v3.77a.76.76,0,0,1-.754.754h-15.1a.746.746,0,0,1-.737-.754V305.5"
        transform="translate(0 -4.181)"
        fill="none"
        stroke="#c1c1c1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        id="Line_136"
        data-name="Line 136"
        y2="8.837"
        transform="translate(200.795 293.99)"
        fill="none"
        stroke="#c1c1c1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const Bill = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="32"
    viewBox="0 0 26 32"
  >
    <g
      id="Icon_feather-file-text"
      data-name="Icon feather-file-text"
      transform="translate(-5 -2)"
    >
      <path
        id="Path_405"
        data-name="Path 405"
        d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_406"
        data-name="Path 406"
        d="M21,3v9h9"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_407"
        data-name="Path 407"
        d="M24,19.5H12"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_408"
        data-name="Path 408"
        d="M24,25.5H12"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_409"
        data-name="Path 409"
        d="M15,13.5H12"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const Rebuild = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="vuesax_linear_code"
      data-name="vuesax/linear/code"
      transform="translate(-492 -252)"
    >
      <g id="code" transform="translate(492 252)">
        <path
          id="Vector"
          d="M0,0A5.244,5.244,0,0,1,2.025,1.792a1.27,1.27,0,0,1,0,1.425A5.291,5.291,0,0,1,0,5"
          transform="translate(5.742 7.5)"
          fill="none"
          stroke="#293231"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H3.333"
          transform="translate(10.833 12.5)"
          fill="none"
          stroke="#293231"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833v-5C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667Z"
          transform="translate(1.667 1.667)"
          fill="none"
          stroke="#293231"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);
