import { gql } from '@apollo/client';

export const ALL_PRODUCTS = gql`
  query AllProducts($currentPage: Int) {
    products(
      filter: { category_id: { eq: "2" } }
      currentPage: $currentPage
      pageSize: 10000
    ) {
      items {
        url_key
      }
      page_info {
        total_pages
      }
      total_count
    }
  }
`;
