// Checkout
import { CREATE_ORDER } from './checkout/createOrder';
import { SET_BILLING_ADDRESS } from './checkout/setBillingAddress';
import { SET_SHIPPING_METHOD } from './checkout/setShippingMethod';
import { SET_GUEST_EMAIL } from './checkout/setGuestEmail';
import { SET_BRAINTREE_PAYMENT_METHOD } from './checkout/payment/setBraintreePaymentMethod';
import { SET_ESTO_PAYMENT_METHOD } from './checkout/payment/setEstoPaymentMethod';

// Cart
import { CREATE_NEW_CART } from './cart/createCart';
import { MERGE_CARTS } from './cart/mergeCarts';
import { ADD_SIMPLE_PRODUCT_TO_CART } from './cart/addSimpleProductToCart';
import { ADD_CONF_PRODUCT_TO_CART } from './cart/addConfProductToCart';
import { UPDATE_CART_ITEM } from './cart/updateCartItem';
import { APPLY_COUPON } from './cart/applyCouponToCart';
import { REMOVE_COUPON } from './cart/removeCouponFromCart';

// Customer
import { CREATE_CUSTOMER } from './account/createCustomer';
import { UPDATE_CUSTOMER } from './account/updateCustomer';
import { UPDATE_CUSTOMER_EMAIL } from './account/updateCustomerEmail';
import { CREATE_CUSTOMER_ADDRESS } from './account/createCustomerAddress';
import { UPDATE_CUSTOMER_ADDRESS } from './account/updateCustomerAddress';
import { GENERATE_TOKEN } from './account/generateToken';
import { GENERATE_TOKEN_AS_ADMIN } from './account/generateTokenAsAdmin';
import { ADD_TO_WISHLIST } from './account/wishlist/addToWishlist';
import { REMOVE_FROM_WISHLIST } from './account/wishlist/removeFromWishlist';
import { REQUEST_PASSWORD_RESET } from './account/requestPasswordReset';
import { RESET_PASSWORD } from './account/resetPassword';

import { CREATE_PRODUCT_REVIEW } from './account/createProductReview';

// Compare list
import { CREATE_COMPARE_LIST } from './account/compareList/createCompareList';
import { ADD_TO_COMPARE_LIST } from './account/compareList/addToCompareList';
import { ASSIGN_COMPARE_LIST_TO_CUSTOMER } from './account/compareList/assignCompareListToCustomer';
import { REMOVE_FROM_COMPARE_LIST } from './account/compareList/removeFromCompareList';
import { DELETE_COMPARE_LIST } from './account/compareList/deleteCompareList';

export {
  // Checkout
  CREATE_ORDER,
  SET_BILLING_ADDRESS,
  SET_SHIPPING_METHOD,
  SET_GUEST_EMAIL,
  SET_BRAINTREE_PAYMENT_METHOD,
  SET_ESTO_PAYMENT_METHOD,
  // Cart
  CREATE_NEW_CART,
  MERGE_CARTS,
  ADD_SIMPLE_PRODUCT_TO_CART,
  ADD_CONF_PRODUCT_TO_CART,
  UPDATE_CART_ITEM,
  APPLY_COUPON,
  REMOVE_COUPON,
  // Customer
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_EMAIL,
  CREATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
  GENERATE_TOKEN,
  GENERATE_TOKEN_AS_ADMIN,
  CREATE_PRODUCT_REVIEW,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  // Compare list
  CREATE_COMPARE_LIST,
  ADD_TO_COMPARE_LIST,
  ASSIGN_COMPARE_LIST_TO_CUSTOMER,
  REMOVE_FROM_COMPARE_LIST,
  DELETE_COMPARE_LIST
};
