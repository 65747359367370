import Cookies from 'universal-cookie';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_URL_GRAPHQL, USER_TOKEN } from 'global/constants';

const httpLink = createHttpLink({
  uri: API_URL_GRAPHQL
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get(USER_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    //  store: `${language}`
    }
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Cart: {
      fields: {
        items: {
          merge: false
        },
        available_payment_methods: {
          merge: false
        }
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
});

export const apolloClientSSR = (locale, cookie) => {
  const authLinkLocale = setContext((_, { headers }) => {
    const cookies = new Cookies(cookie);
    const token = cookies.get(USER_TOKEN);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        store: `${locale}`
      }
    };
  });
  //return {link, cache}
  return new ApolloClient({
    link: authLinkLocale.concat(httpLink),
    cache: new InMemoryCache()
  });
};