import { gql } from '@apollo/client';

export const CMS_BLOCK = gql`
  query GetCMSBlock($identifier: String!) {
    cmsBlocks(identifiers: [$identifier]) {
      items {
        content
      }
    }
  }
`;
