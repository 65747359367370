import Head from 'next/head';
import { useRouter } from 'next/router';
import { CLIENT } from 'global/constants';

export default function Seo({ seo, children }) {
  const router = useRouter();
  const url = `${CLIENT}${router.asPath}`;

  return (
    <Head>
      <title>{seo.title.includes('undefined') ? 'Baltbaby' : seo.title}</title>

      <meta name="title" content={seo.title} key="metaTitle" />
      {seo.description && (
        <meta
          name="description"
          content={seo.description}
          key="metaDescription"
        />
      )}

      <meta property="og:title" content={seo.title} key="ogTitle" />
      {seo.description && (
        <meta
          property="og:description"
          content={seo.description}
          key="ogDescription"
        />
      )}
      {seo.image && (
        <meta property="og:image" content={seo.image} key="ogImage" />
      )}
      <meta property="og:url" content={url} key="ogUrl" />

      <link rel="canonical" href={url} key="canonicalUrl" />

      {children}
    </Head>
  );
}
