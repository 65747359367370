import { gql } from '@apollo/client';

export const CATEGORY_NAME = gql`
  query CategoryName($name: String!) {
    categoryList(filters: { url_key: { eq: $name } }) {
      name
      url_key
      breadcrumbs {
        category_name
        category_url_key
      }
    }
  }
`;
