import { gql } from '@apollo/client';

export const BESTSELLERS_SECOND = gql`
  query Bestsellers {
    products(
      filter: { }
      sort: { bestsellers: DESC }
      currentPage: 1
      pageSize: 12
    ) {
      items {
        __typename
        name
        sku
        url_key
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        image {
          label
          url
        }
        media_gallery {
          label
          url
        }
        categories {
          name
          level
          url_key
        }
        rating_summary
      }
    }
  }
`;
