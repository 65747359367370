import { gql } from '@apollo/client';

export const BRAND = gql`
  query Brands($urlKey: String!) {
    mpbrand(filter: { url_key: { eq: $urlKey } }) {
      items {
        value
        image
        description
        short_description
      }
    }
  }
`;
