import { gql } from '@apollo/client';

export const CREATE_PRODUCT_REVIEW = gql`
  mutation CreateProductReview(
    $sku: String!
    $nickname: String!
    $text: String!
    $value: String!
  ) {
    createProductReview(
      input: {
        sku: $sku
        nickname: $nickname
        summary: "-"
        text: $text
        ratings: [{ id: "NA==", value_id: $value }]
      }
    ) {
      review {
        nickname
        summary
        text
        average_rating
        ratings_breakdown {
          name
          value
        }
      }
    }
  }
`;
