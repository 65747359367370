export default {
  colors: {
    white: '#fff',
    black: '#1B1B1B',
    'teal-100': '#96E7E7',
    'teal-200': '#45AAC4',
    'teal-300': '#52C1D3',
    'teal-400': '#54C8E6',
    'gray-100': '#8D8D8D',
    'gray-200': '#9F9F9F',
    'gray-300': '#242424',
    'blue-100': '#F7FCFE',
    'blue-200': '#4E6973',
    'pink-100': '#FE93BF',
    'purple-100': '#D8D9E5',
    'purple-200': '#A8A9BA'
  },
  fontSizes: {
    xxsmall: '0.8125rem', // 13px
    xsmall: '1rem', // 16px
    small: '1.1785rem', // 19px
    medium: '1.4375rem', // 23px
    large: '1.6875rem', // 27px
    xlarge: '2.0626rem', // 33px
    xxlarge: '2.4375rem', // 39px
    xxxlarge: '2.9375rem' // 47px
  },
  screens: {
    _: 0,
    sm_mobile: 350,
    mobile: 385,
    lg_mobile: 423,
    sm_tablet: 520,
    tablet: 640,
    lg_tablet: 768,
    desktop: 1024,
    lg_desktop: 1280
  }
};
