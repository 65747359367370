import { gql } from '@apollo/client';

export const ADD_TO_WISHLIST = gql`
  mutation AddToWishlist($id: ID!, $item: WishlistItemInput!) {
    addProductsToWishlist(wishlistId: $id, wishlistItems: [$item]) {
      wishlist {
        id
        items_count
        items_v2 {
          items {
            id
            quantity
            ... on BundleWishlistItem {
              bundle_options {
                values {
                  id
                  label
                  quantity
                }
              }
            }
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                }
              }
              price {
                regularPrice {
                  amount {
                    value
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;
