import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($id: Int!, $input: CustomerAddressInput) {
    updateCustomerAddress(id: $id, input: $input) {
      telephone
      street
      city
      region {
        region
        region_code
        region_id
      }
      postcode
      id
    }
  }
`;
