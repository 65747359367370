import { CUSTOMER } from './getCustomer';
import { CART } from './getCart';
import { CATEGORIES_BASE_DATA } from './getCategoriesBaseData';
import { CATEGORIES } from './getCategories';
import { CATALOGUE_CATEGORIES } from './getCatalogueCategories';
import { CATEGORY_NAME } from './getCategoryName';
import { CATEGORY_PRODUCTS } from './getCategoryProducts';
import { CUSTOM_ATTRIBUTE_VALUES } from './getCustomAttributeValues';
import { SEARCHED_PRODUCTS } from './getSearchedProducts';
import { BRAND_PRODUCTS } from './getBrandProducts';
import { BESTSELLERS } from './getBestsellers';
import { ALL_PRODUCTS } from './getAllProducts';
import { PRODUCT } from './getProduct';
import { COMPARE_LIST } from './getCompareList';
import { PROVINCES } from './getProvinces';
import { STATIC_PAGE } from './getCMSPage';
import { BLOG_POSTS } from './getBlogPosts';
import { BLOG_POST } from './getBlogPost';
import { BRANDS } from './getBrands';
import { BRAND } from './getBrand';
import { CMS_BLOCK } from './getCMSBlock';
import { BLOG_POSTS_FRONT } from './getFrontBlogPosts';
import { FEATURED_BRANDS } from './getFeaturedBrands';
import { RELATED_PRODUCTS } from './getRelatedProduct';
import { BESTSELLERS_SECOND } from './getBestsellersSecond';
import { CATEGORY_PRODUCTS_HOME } from './getCategoryProductsHome';
import { GET_VARIATION_IMAGE } from './getVariationImage';
import { GET_ROUTE } from './getRoutes';

export {
  CUSTOMER,
  CART,
  CATEGORIES_BASE_DATA,
  CATEGORIES,
  CATALOGUE_CATEGORIES,
  CATEGORY_NAME,
  CATEGORY_PRODUCTS,
  CUSTOM_ATTRIBUTE_VALUES,
  SEARCHED_PRODUCTS,
  BRAND_PRODUCTS,
  BESTSELLERS,
  ALL_PRODUCTS,
  PRODUCT,
  COMPARE_LIST,
  PROVINCES,
  STATIC_PAGE,
  BLOG_POSTS,
  BLOG_POST,
  BRANDS,
  BRAND,
  CMS_BLOCK,
  BLOG_POSTS_FRONT,
  FEATURED_BRANDS,
  RELATED_PRODUCTS,
  BESTSELLERS_SECOND,
  CATEGORY_PRODUCTS_HOME,
  GET_ROUTE,
  GET_VARIATION_IMAGE
};
