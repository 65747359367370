import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER } from 'graphql/queries';
import { useAtom } from 'jotai';
import { authAtom, customerAtom } from 'global/store';
import { apolloClient } from 'graphql/apollo-client';
import { useRouter } from 'next/router';

export default function useCustomerState() {
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';
  const [{ token: isAuth }] = useAtom(authAtom);
  const [customer, setCustomer] = useAtom(customerAtom);

  const [getCustomer, { loading }] = useLazyQuery(CUSTOMER, {
    client: apolloClient,
    fetchPolicy: 'network-only',
    context: { fetchOptions: { method: 'GET' }, headers: { store: lang } },
    onCompleted: data => {
      if (isAuth && data?.customer) {
        setCustomer(data?.customer);
      }
    }
  });

  useEffect(() => (isAuth ? getCustomer() : setCustomer(null)), [isAuth]);

  return { customer, loading };
}
