import { gql } from '@apollo/client';

export const PRODUCT = gql`
  query GetProduct($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey } }) {
      items {
        __typename
        id
        name
        sku
        url_key
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        image {
          url
          label
        }
        media_gallery {
          url
          label
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        description {
          html
        }
        short_description {
          html
        }
        mpbrand {
          value
          image
          url_key
          option_id
        }
        categories {
          name
          level
          url_key
          breadcrumbs {
            category_name
            category_level
            category_url_key
          }
        }
        reviews {
          items {
            average_rating
            created_at
            nickname
            ratings_breakdown {
              name
              value
            }
            summary
            text
          }
        }
        rating_summary
        ... on ConfigurableProduct {
          configurable_options {
            label
            attribute_code
            values {
              uid
              label
            }
          }
          variants {
            attributes {
              uid
              code
              label
            }
            product {
              id
              sku
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              image {
                url
                label
              }
              media_gallery {
                url
                label
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
