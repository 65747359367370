import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($input: CustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      telephone
      street
      city
      region {
        region
        region_code
        region_id
      }
      postcode
      id
    }
  }
`;
