import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { CART_ID } from 'global/constants';

export default function createCartCookie(id) {
  const cookies = new Cookies();
  // Set to expire in 1 day
  const expires = dayjs().add(1, 'd').toDate();
  // Set cart ID as cookie
  cookies.set(CART_ID, id, { path: '/', expires });
}
