import { gql } from '@apollo/client';

export const GET_ROUTE = gql`
  query getRoute($url: String!) {
    route(url: $url) {
      __typename
      relative_url
      redirect_code
      type
    }
  }
`;
