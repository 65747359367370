import { gql } from '@apollo/client';
import { CART_FIELDS } from 'graphql/fragments/cart';

export const APPLY_COUPON = gql`
  ${CART_FIELDS}

  mutation ApplyCoupon($cartId: String!, $code: String!) {
    applyCouponToCart(input: { cart_id: $cartId, coupon_code: $code }) {
      cart {
        ...CartFields
      }
    }
  }
`;
