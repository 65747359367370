import { gql } from '@apollo/client';
import { CART_FIELDS } from '../../fragments/cart';

export const ADD_SIMPLE_PRODUCT_TO_CART = gql`
  mutation AddSimpleProductToCart($cartId: String!, $sku: String!) {
    addProductsToCart(
      cartId: $cartId
      cartItems: [{ quantity: 1, sku: $sku }]
    ) {
      cart {
        id
        email
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
            }
            price_incl_tax {
              value
            }
          }
          selected_shipping_method {
            amount {
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        items {
          uid
          product {
            name
            sku
            url_key
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            price {
              regularPrice {
                amount {
                  value
                }
              }
            }
            image {
              url
              label
            }
            media_gallery {
              url
              label
            }
            categories {
              name
            }
          }
          quantity
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
            prices {
              row_total_including_tax {
                value
              }
            }
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
          }
          discounts {
            amount {
              value
            }
          }
          subtotal_including_tax {
            value
          }
        }
        total_quantity
      }
    }
  }
`;
