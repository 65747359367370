import { gql } from '@apollo/client';

export const SET_BILLING_ADDRESS = gql`
  mutation SetBillingAddress($cartId: String!, $address: CartAddressInput) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cartId
        billing_address: { address: $address, same_as_shipping: true }
      }
    ) {
      cart {
        id
      }
    }
  }
`;
